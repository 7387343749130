import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "../store"; // Make sure this import is correct
import axios from "axios"; // Add this import
const API =process.env.REACT_APP_API_KEY;

const initialState = {
  isLoading: false,
  error: null,
  containStore: [],
};

const containStoreSlice = createSlice({
  name: "containStore",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.containStore = action.payload; // Correct the state property to eventError
    },

    addContainStoreSuccess(state, action) {
      state.isLoading = false;
      state.containStore = action.payload; // Use the spread operator
    },
  },
});
export const { startLoading, hasError, addContainStoreSuccess } = containStoreSlice.actions;

export default containStoreSlice.reducer;

const token=localStorage.getItem('token')
export  function getContainStore1(category) {
    return async (dispatch) => {
      dispatch(containStoreSlice.actions.startLoading()); // Use the imported startLoading action
      try {
        const response = await axios.get(`${API}/demand/contain-store-one?category=${category}`,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        });
        console.log("response", response);
      // Dispatch addEventSuccess with the response data
      dispatch(addContainStoreSuccess(response.data));
      return response;
      } catch (error) {
        console.log("Error", error);
        dispatch(hasError(error.message)); // Use the imported hasError action
      }
    };
  }


  export  function getContainStore2(category) {
    return async (dispatch) => {
      dispatch(containStoreSlice.actions.startLoading()); // Use the imported startLoading action
      try {
        const response = await axios.get(`${API}/demand/contain-store-two?category=${category}`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        });
        console.log("response", response);
      // Dispatch addEventSuccess with the response data
      dispatch(addContainStoreSuccess(response.data));
      return response;
      } catch (error) {
        console.log("Error", error);
        dispatch(hasError(error.message)); // Use the imported hasError action
      }
    };
  }
import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "../store"; // Make sure this import is correct
import axios from "axios"; // Add this import
const API = process.env.REACT_APP_API_KEY;
console.log(API)
const initialState = {
    isRMLoading: false,
    rmError: null,
    rm: [],
};

const rmSlice = createSlice({
    name: "rm",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isRMLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isRMLoading = false;
            state.rmError = action.payload; // Correct the state property to eventError
        },

        addRMSuccess(state, action) {
            state.isRMLoading = false;
            state.rm = action.payload; // Use the spread operator
        },
    },
});

export const { startLoading, hasError, addRMSuccess } = rmSlice.actions;

export default rmSlice.reducer;

// Actions
const token=localStorage.getItem('token')
export function getRMStore(category) {
    return async (dispatch) => {
        dispatch(rmSlice.actions.startLoading()); // Use the imported startLoading action
        try {

            const response = await axios.get(`${API}/demand/rm-store?category=${category}`,  {
                headers:{
                  Authorization: `Bearer ${token}`
                }
              });
            console.log("working response", response);
            // Dispatch addEventSuccess with the response data
            dispatch(addRMSuccess(response.data));
            return response;
        }
        catch (error) {
            console.log("Error", error);
            dispatch(hasError(error.message)); // Use the imported hasError action
        }
    };
}

export function getRMDemand(category) {
    return async (dispatch) => {
        dispatch(rmSlice.actions.startLoading()); // Use the imported startLoading action
        try {

            const response = await axios.get(`${API}/demand/raw-materials?category=${category}`,  {
                headers:{
                  Authorization: `Bearer ${token}`
                }
              });
            console.log("working response", response);
            // Dispatch addEventSuccess with the response data
            dispatch(addRMSuccess(response.data));
            return response;
        }
        catch (error) {
            console.log("Error", error);
            dispatch(hasError(error.message)); // Use the imported hasError action
        }
    };
}



import { Paper, Button, TextField, Input, Stack, Backdrop, CircularProgress } from "@mui/material";
import React, { useState, useCallback } from "react";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import SoftInput from "components/SoftInput";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";
import axios from "axios";
import { Dropzone, FileMosaic } from "@files-ui/react";

function ExcelUpload() {
  const API = process.env.REACT_APP_API_KEY;
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(files);

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };
  const [status, setStatus] = useState(false);

  // const onDrop = useCallback((acceptedFiles) => {
  //   setStatus(true);
  //   if (acceptedFiles.length > 0) {
  //     const file = acceptedFiles[0];
  //     setTypeError(null);

  //     try {
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         setExcelFile(e.target.result);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     } catch (error) {
  //       setTypeError("Error reading file");
  //       console.error("Error reading file:", error);
  //     }
  //   }
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // const [excelFile, setExcelFile] = useState(null);
  // console.log("excellFile", excelFile);
  const token = localStorage.getItem("token");
  const handleFileSubmit = (e) => {
    setLoading(true);
    console.log("clicked");
    // e.preventDefault();
    if (files !== null) {
      try {
        const handleSubmit = async () => {
          console.log("here");
          const formData = new FormData();
          formData.append("file", files[0].file);
          formData.append("comment", comment);

          const response = await axios.put(`${API}/update`, formData, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the request headers
            },
          });
          if (response.status === 200) {
            setLoading(false);
            showAlert();
          }
        };
        handleSubmit();
      } catch (error) {
        setLoading(false);
        console.error("Error processing Excel file:", error);
      }
    }
  };
  const showAlert = () => {
    Swal.fire("Done", "Excell Uploaded Succesfully", "success");
    setTimeout(() => {
      navigate("/dashboards/default");
    }, 1500);
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", new Blob([excelFile], { type: "application/octet-stream" }));
    formData.append("comment", comment);

    const response = await axios.put(`${API}/update`, formData, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the request headers
      },
    });
    // showAlert();
  };

  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />{" "}
      <div
        style={{
          minHeight: "660px",
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          padding: "20px",
          marginTop: "70px",
        }}
        // className="wrapper"
      >
        <Dropzone onChange={updateFiles} value={files}>
          {files.map((file, key) => (
            <FileMosaic key={key} {...file} resultOnTooltip alwaysActive preview info />
          ))}
        </Dropzone>

        {files.length != 0 && (
          <Stack
            style={{
              width: "500px   ",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SoftInput
              placeholder="Add a comment about your file here"
              multiline
              rows={3}
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ marginBottom: "10px" }}
            />

            <Button
              onClick={() => handleFileSubmit()}
              sx={{ background: "linear-gradient(310deg, #2152ff, #21d4fd);", color: "#ffff" }}
              variant="contain"
            >
              UPLOAD
            </Button>
          </Stack>
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

// Function to apply styles for the dropzone area
const dropzoneStyles = (isDragActive) => ({
  border: isDragActive ? "2px dashed #2196F3" : "2px dashed #9e9e9e",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
});

export default ExcelUpload;

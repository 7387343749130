import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_API_KEY;

const initialState = {
  isDemandLoading: false,
  demandError: null,
  demand: [],
};

const demandSlice = createSlice({
  name: "demand",
  initialState,
  reducers: {
    startLoading(state) {
      state.isDemandLoading = true;
    },
    hasError(state, action) {
      state.isDemandLoading = false;
      state.demandError = action.payload;
    },
    addDemandSuccess(state, action) {
      state.isDemandLoading = false;
      state.demand = action.payload;
    },
  },
});

export const { startLoading, hasError, addDemandSuccess } = demandSlice.actions;

export default demandSlice.reducer;

export function getDemand(category) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const token = localStorage.getItem("token"); // Retrieve token here
      let response;
      if (!category) {
        response = await axios.get(`${API}/demand`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.get(`${API}/demand?category=${category}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      dispatch(addDemandSuccess(response.data.response));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message));
    }
  };
}

export function getDemandPrice(category) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const token = localStorage.getItem("token"); // Retrieve token here
      let response;
      if (!category) {
        response = await axios.get(`${API}/demand/price`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.get(`${API}/demand/price?category=${category}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      dispatch(addDemandSuccess(response.data.price_demand));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message));
    }
  };
}

export function getDemandDetailed(category) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const token = localStorage.getItem("token"); // Retrieve token here
      const response = await axios.get(`${API}/demand/detailed?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(addDemandSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message));
    }
  };
}

/*
 * Project: [Oushadhi Live Demand App]
 * version:1.0.1
 * copyright__ = [Copyright © 2024 [Trigeminal.AI]. All rights reserved.]
 * Organization: [Trigeminal.AI]
 * File: [App.js]
 * Description: [This file serves as the main entry point for the React application. It renders the top-level component, which includes the overall structure of the app. The component hierarchy, routing, and global state management are initialized here. Additionally, it may include any global styles or theme configurations. Modifications to this file impact the entire application's structure and behavior.]
 * Date: [29/01/2024]
 */

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "Context";

// Images
import brand from "assets/images/logo-ct.png";
import { Box } from "@mui/material";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";
import page_Routes from "page_routes";
import Footer from "examples/Footer";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import axios from "axios";
import bgimage from "assets/images/bg.png";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Check the current path
      const currentPath = window.location.pathname;

      if (currentPath !== "/authentication/sign-in/illustration") {
        window.location.href = "/authentication/sign-in/illustration";
      }
    }
    return Promise.reject(error.message);
  }
);

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const isSignInPage = pathname === "/authentication/sign-in/illustration"; // Check the path
  console.log(isSignInPage, pathname, "page name");
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     window.location.href = "/authentication/sign-in/illustration";
  //   }
  // }, []);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return direction === "rtl" ? (
    <Provider store={store}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              {/* <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Soft UI Dashboard PRO10"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                /> */}
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/authentication/sign-in/illustration" />} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  ) : (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Soft UI Dashboard PRO11"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              /> */}
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundImage: isSignInPage ? `url(${bgimage})` : "none",
            width: "100%",
            // height: "100vh",
            height: "100%",
            padding: "20px",
          }}
        >
          {/* <DefaultNavbar
          routes={page_Routes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          // transparent
          dark
        /> */}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/authentication/sign-in/illustration" />} />
          </Routes>
        </Box>
        <Footer />
      </ThemeProvider>
    </Provider>
  );
}

import { combineReducers } from "redux";

// Slices
import demandReducer from "./slices/demand";
import rmReducer from "./slices/rawMaterial"
import containStoreReducer from "./slices/containStore"
import marketingReducer from "./slices/marketing"
import productionReducer from "./slices/production"
// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  demand: demandReducer,
  rm:rmReducer,
  containStore:containStoreReducer,
  marketing:marketingReducer,
  production:productionReducer
});

export default rootReducer;

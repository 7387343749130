import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "../store"; // Make sure this import is correct
import axios from "axios"; // Add this import
const API = process.env.REACT_APP_API_KEY;

const initialState = {
  isLoading: false,
  error: null,
  production: [],
};

const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.production = action.payload; // Correct the state property to eventError
    },

    addProductionSuccess(state, action) {
      state.isLoading = false;
      state.production = action.payload; // Use the spread operator
    },
  },
});

export const { startLoading, hasError, addProductionSuccess } = productionSlice.actions;

export default productionSlice.reducer;
const token = localStorage.getItem("token");
export function getProductionDemand(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(
        `${API}/demand/production-and-marketing?category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}

export function getDemandBatches(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(`${API}/demand/demand-batches?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getConsolidatedDemandBatches(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(
        `${API}/demand/demand-batches-consolidated?category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getLiveDemandSo(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(`${API}/raw_demand/sales-order?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getLiveDemandRaw(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(`${API}/raw_demand/demand-batches?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getActualConsolidatedDemandBatches(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action

    try {
      const response = await axios.get(`${API}/raw_demand/consolidated?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getConsolidatedDemandBatchesConfirmed(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action

    try {
      const response = await axios.get(
        `${API}/reserved/batches-consolidated?category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getLiveDemandConfirmed(category) {
  return async (dispatch) => {
    dispatch(productionSlice.actions.startLoading()); // Use the imported startLoading action

    try {
      const response = await axios.get(`${API}/reserved/batches?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addProductionSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}

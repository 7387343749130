import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";

function Dashboard4() {
  const columns = [
    { headerName: "ID", field: "id" },
    { headerName: "Item", field: "item" },
    { headerName: "Unit", field: "unit" },
    { headerName: "Stock", field: "stock" },
    { headerName: "Live Demand", field: "liveDemand" },
    { headerName: "Stock Invoice", field: "stockInvoice" },
    { headerName: "30/04/24", field: "date1" },
    { headerName: "30/04/24", field: "date2" },
    { headerName: "30/04/24", field: "date3" },
    { headerName: "30/04/24", field: "date4" },
    { headerName: "30/04/24", field: "date5" },
  ];
  const rows = [
    {
      id: 1,
      item: "Abhaya Choornam",
      unit: 1,
      stock: 1000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 2,
      item: "Bala Thailam",
      unit: 15,
      stock: 2000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 3,
      item: "Cardio Care Tablet",
      unit: 21,
      stock: 3000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 4,
      item: "Danthi Choornam",
      unit: 41,
      stock: 4000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 5,
      item: "Eladi Rasayanam",
      unit: 501,
      stock: 5000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 6,
      item: "Gandha Thailam",
      unit: 951,
      stock: 6000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 7,
      item: "Hridyavirechanam",
      unit: 1352,
      stock: 7000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 8,
      item: "Jathyadi Thailam",
      unit: 1250,
      stock: 8000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 9,
      item: "Kadali Rasayanam",
      unit: 4621,
      stock: 9000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
    {
      id: 10,
      item: "Lakshadi Kuzhambu",
      unit: 8521,
      stock: 10000,
      liveDemand: 1000,
      stockInvoice: 900,
      date1: 10,
      date2: 20,
      date3: 30,
      date4: 40,
      date5: 100,
    },
  ];
  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />{" "}
      <Box sx={{ backgroundColor: "#FFFF", marginTop: "20px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}

export default Dashboard4;

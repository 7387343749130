import React from "react";

import RankingsList from "examples/Lists/RankingsList";
import SoftBox from "components/SoftBox";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";

function ListUpload() {
  return (
    // Soft UI Dashboard PRO React examples
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "100px", minHeight: "650px", paddingInline: "300px" }}>
        <RankingsList
          title="report uploads"
          // date="23 - 30 March 2021"
          rankings={[
            {
              color: "error",
              icon: "arrow_upward",
              name: "Demand Upload",
              description: "27 March 2020, at 12:30 PM",
              value: "- ₹ 2500000",
            },
            {
              color: "success",
              icon: "arrow_upward",
              name: "MTS Upload",
              description: "23 March 2020, at 04:30 AM",
              value: "+ ₹ 2000000",
            },
            {
              color: "warning",
              icon: "arrow_upward",
              name: "Raw material stock upload",
              description: "19 March 2020, at 02:50 AM",
              value: "- ₹ 1400000",
            },
          ]}
        />
      </SoftBox>
    </>
  );
}

export default ListUpload;

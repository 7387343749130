import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { AgGridReact } from "ag-grid-react";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DataTable from "examples/Tables/DataTable";
import page_Routes from "page_routes";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import React, { useEffect, useState } from "react";
import { getContainStore1 } from "../../../../redux/slices/containStore";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import axios from "axios";

function ContainStore1() {
  const dispatch = useDispatch(); // Redux dispatch function
  const [category, setCategory] = useState("all");
  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated
  const token = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getContainStore1(category)); // Call getContainStore1 action
    const getDate = async () => {
      try {
        const res = await axios.get("https://oushadhi-api.trigeminal-ai.com/date", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);

  const { containStore } = useSelector((state) => state.containStore);

  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "90px",
      pinned: "left",
    },
    {
      headerName: "Product Code",
      field: "packing material code",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "150px",
    },
    {
      headerName: "Packaging Material",
      field: "packing material",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "300px",
    },
    {
      headerName: "Unit",
      field: "unit",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "120px",
    },
    { headerName: "Stock", field: "stock", sortable: true, filter: true, width: "100px" },
    {
      headerName: "Min Stock",
      field: "minimum stock",
      sortable: true,
      filter: true,
      width: "120px",
    },
    { headerName: "Max Stock", field: "maximum", sortable: true, filter: true, width: "140px" },
    {
      headerName: "Live Demand",
      field: "live demand",
      sortable: true,
      filter: true,
      width: "140px",
    },
    {
      headerName: "Net Demand",
      field: "excess/short",
      sortable: true,
      filter: true,
      width: "150px",
    },
  ];
  const exportToExcel = () => {
    // Format data for export
    const exportData = containStore.map((row, index) => ({
      "Sl No": index + 1,
      "Product Code": row["packing material code"],
      "Packaging Material": row["packing material"],
      Unit: row.unit,
      Stock: row.stock,
      "Min Stock": row["minimum stock"],
      "Max Stock": row.maximum,
      "Live Demand": row["live demand"],
      "Net Demand": row["excess/short"],
      // GP: row.gp, // Uncomment if GP is required
      // Total: row.total,
    }));

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "PM Live demand");

    // Trigger the download
    XLSX.writeFile(workbook, "PM_live_demand.xlsx");
  };

  const buttonArray = [
    "ALUM FOIL",
    "CARTONS",
    "DUPX CART",
    "LABELS",
    "LAM SHEET",
    "MISCELLAN",
    "OTHERS",
    "PET BOTTL",
    "PLAS CONT",
    "POLY BAGS",
    "STANRY",
    "STIK LABL",
    "STIK ROLL",
    "SUN ARTKL",
  ];

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      PM Live Demand{" "}
    </Typography>,
  ];

  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <UploadFileRoundedIcon
                    sx={{
                      cursor: "pointer", // Change cursor to pointer on hover
                      color: "#1D81F6", // Default color
                      fontSize: "1.5rem !important", // Increase the size of the icon
                      transition: "0.3s",
                      mx: 3, // Smooth transition for hover effects
                      "&:hover": {
                        color: "#4DC3F8", // Change color on hover
                        fontSize: "2.5rem", // Increase icon size on hover
                      },
                    }}
                    onClick={exportToExcel}
                  />
                  <Chip
                    size="small"
                    label={`Demand  updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  paginationPageSize={20}
                  rowData={containStore}
                  columnDefs={columnDefs}
                  pagination={true}
                  // fullWidthCellRenderer={}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default ContainStore1;

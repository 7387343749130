import { Backdrop, Box, Chip, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from "xlsx";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DataTable from "examples/Tables/DataTable";
import page_Routes from "page_routes";
import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { getRMDemand } from "../../../../redux/slices/rawMaterial";
import { useDispatch, useSelector } from "react-redux";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import axios from "axios";
function PurchaseRawMeterial() {
  const dispatch = useDispatch(); // Redux dispatch function
  const [category, setCategory] = useState("all");
  const [open, setOpen] = useState(false);
  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      RM Live Demand - SO{" "}
    </Typography>,
  ];

  const { rm, isRMLoading } = useSelector((state) => state.rm);
  const token = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getRMDemand(category)); // Call getContainStore1 action
    const getDate = async () => {
      try {
        const res = await axios.get("https://oushadhi-api.trigeminal-ai.com/date", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);

  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "90px",
      pinned: "left",
    },
    {
      headerName: "RM Code",
      field: "rm_code",
      sortable: true,
      filter: true,
      width: "150px",
      pinned: "left",
    },
    {
      headerName: "RM Description",
      field: "raw_material_name",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "200px",
    },
    {
      headerName: "Unit",
      field: "raw_material_unit",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "120px",
    },
    {
      headerName: "Min Stock",
      field: "minimum_stock",
      sortable: true,
      filter: true,
      width: "120px",
    },

    { headerName: "Stock", field: "stock", sortable: true, filter: true, width: "100px" },
    {
      headerName: "Live Demand",
      field: "raw_material_demand",
      sortable: true,
      filter: true,
      width: "140px",
    },
    {
      headerName: "Net Demand",
      field: "net_demand",
      sortable: true,
      filter: true,
      width: "150px",
    },
    {
      headerName: "Net Demand(INR)",
      field: "raw_material_price",
      sortable: true,
      filter: true,
      width: "180px",
    },
  ];
  const exportToExcel = () => {
    // Format data for export
    const exportData = rm.map((row, index) => ({
      "Sl No": index + 1,
      "RM Code": row.rm_code,
      "RM Description": row.raw_material_name,
      Unit: row.raw_material_unit,
      "Min Stock": row.minimum_stock,
      Stock: row.stock,
      "Live Demand": row.raw_material_demand,
      "Net Demand": row.net_demand,
      "Net Demand (INR)": row.raw_material_price,
      // GP: row.gp, // Uncomment if GP is required
    }));

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "RM Live demand-SO");

    // Trigger the download
    XLSX.writeFile(workbook, "RM_live_demand-SO.xlsx");
  };
  const buttonArray = [
    "BARK",
    "BONES/NAI",
    "EXCRETA",
    "EXTRACTS",
    "FIREWOOD",
    "FLESH",
    "FLOWERS",
    "FRUITS",
    "LATEX/RES",
    "LEAVES",
    "MILK PROD",
    "MINERAL",
    "RHIZOMES",
    "ROOTS",
    "THAI",
    "TWINERS",
    "WHOLE PLA",
    "WOOD",
    "OILS",
    "MISCELLAN",
    "OTHERS",
  ];
  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <UploadFileRoundedIcon
                    sx={{
                      cursor: "pointer", // Change cursor to pointer on hover
                      color: "#1D81F6", // Default color
                      fontSize: "1.5rem !important", // Increase the size of the icon
                      transition: "0.3s",
                      mx: 3, // Smooth transition for hover effects
                      "&:hover": {
                        color: "#4DC3F8", // Change color on hover
                        fontSize: "2.5rem", // Increase icon size on hover
                      },
                    }}
                    onClick={exportToExcel}
                  />
                  <Chip
                    size="small"
                    label={`Demand  updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  paginationPageSize={20}
                  rowData={rm}
                  columnDefs={columnDefs}
                  pagination={true}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "5px",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  sx={{ fontSize: "10px" }}
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default PurchaseRawMeterial;

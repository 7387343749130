import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "../store"; // Make sure this import is correct
import axios from "axios"; // Add this import
const API = process.env.REACT_APP_API_KEY;

const initialState = {
  isLoading: false,
  error: null,
  marketing: [],
};

const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.marketing = action.payload; // Correct the state property to eventError
    },

    addMarketingSuccess(state, action) {
      state.isLoading = false;
      state.marketing = action.payload; // Use the spread operator
    },
  },
});

export const { startLoading, hasError, addMarketingSuccess } = marketingSlice.actions;

export default marketingSlice.reducer;

const token = localStorage.getItem("token");
export function getPreApprovedStock(category) {
  return async (dispatch) => {
    dispatch(marketingSlice.actions.startLoading()); // Use the imported startLoading action
    try {
      const response = await axios.get(`${API}/demand/preapproved-stock?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addMarketingSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}
export function getPreBudgetStock(category) {
  return async (dispatch) => {
    dispatch(marketingSlice.actions.startLoading());
    // Use the imported startLoading action
    try {
      const response = await axios.get(`${API}/budgeting/report?category=${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      // Dispatch addEventSuccess with the response data
      dispatch(addMarketingSuccess(response.data));
      return response;
    } catch (error) {
      console.log("Error", error);
      dispatch(hasError(error.message)); // Use the imported hasError action
    }
  };
}

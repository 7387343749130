import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Box)(({ theme, ownerState }) => {
  const { palette, functions } = theme;
  const { variant, bgColor } = ownerState;

  const { gradients } = palette;
  const { linearGradient } = functions;

  let backgroundValue = bgColor;

  if (variant === "gradient") {
    backgroundValue = linearGradient(gradients[bgColor]?.main, gradients[bgColor]?.state);
  }

  return {
    background: backgroundValue,
    color: "info", // Make sure text is visible on the gradient
  };
});

const CustomSeparator = ({ breadcrumbs }) => {
  return (
    <StyledCard
      ownerState={{ variant: "gradient", bgColor: "info" }}
      sx={{
        height: "25px",
        width: "300px",
        marginLeft: "40px",
        paddingInline: "20px",
        borderRadius: "10px",
        // color: "primary",
        // variant: "gradient",
        // // bgColor: "primary",
        // backgroundColor: "#2C92F7",
        // background: "gradient",
      }}
    >
      <Stack spacing={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </StyledCard>
  );
};

export default CustomSeparator;
CustomSeparator.propTypes = {
  breadcrumbs: PropTypes.array,
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};
